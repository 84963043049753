import { Permission, PermissionItem } from '@/interfaces/session'

export const checkPermission = (value: string, permission: Array<Permission>):boolean => {
  if (!permission) return true
  return permission.some(item => item.path === value || item.name === value)
}

export const checkPermissionItem = (value: string, permission: Array<PermissionItem>):boolean => {
  if (!permission) return true
  return permission.some(item => item.url === value || item.method === value || item.name === value)
}

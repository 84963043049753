import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => [_ctx.showModal ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "general-modal",
      onClick: _cache[0] || (_cache[0] = _withModifiers( //@ts-ignore
      (...args) => _ctx.maskClicked && _ctx.maskClicked(...args), ["stop", "prevent"]))
    }, [_renderSlot(_ctx.$slots, "default")])) : _createCommentVNode("", true)]),
    _: 3
  })]);
}
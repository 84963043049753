import router from './router'
import store from './store'

router.beforeEach(async (to, from, next) => {
  const userName = store.getters.name
  if (userName !== undefined) {
    next()
  } else {
    if (to.path === '/login' || (to.path === '/' && from.path !== '/login') || to.path === '/500') {
      next()
    } else {
      try {
        await store.dispatch('getInfo')
        next({ ...to, replace: true })
      } catch (error) {
        console.error(error)
        if (to.path === '/' && error === '401') {
          next()
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx082902ad51f70c9c&redirect_uri=http%3A%2F%2F${location.host}%2Fapi%2Faccount%2Fwechat%2Fcallback&response_type=code&scope=snsapi_userinfo&state=${to.path}#wechat_redirect`
          next({ path: '/' })
        }
      }
    }
  }
})

import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
import _imports_0 from '../assets/svgs/icon_pop_right.svg';
import _imports_1 from '../assets/svgs/icon_pop_wrong.svg';
const _hoisted_1 = {
  class: "general-modal-wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "general-modal-wrapper-icon",
  src: _imports_0
};
const _hoisted_3 = {
  key: 1,
  class: "general-modal-wrapper-icon",
  src: _imports_1
};
const _hoisted_4 = {
  class: "general-modal-wrapper-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.type === 'success' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3)), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.message), 1)]);
}
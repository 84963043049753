import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/permission'
import axios from 'axios'
import { store, key } from '@/store'

import VConsole from 'vconsole'

import wx from 'weixin-js-sdk'
import { getWechatJSSignature } from '@/api/basic'

const url = window.location.href.split('#')[0]
getWechatJSSignature(url)
  .then(response => {
    wx.config({
      debug: false,
      appId: response.data.appId,
      timestamp: response.data.timestamp,
      nonceStr: response.data.nonceStr,
      signature: response.data.signature,
      jsApiList: [
        'uploadImage',
        'hideAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
        'chooseImage',
        'previewImage',
        'getNetworkType',
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ]
    })
  })

axios
  .get('/shake', {
    timeout: 1000
  })
  .catch(() => {
    router.replace('/500').then(r => { console.log(r) })
  })

const shareOptions = {
  title: '福生财移动管家',
  desc: '点击查看详情',
  link: url,
  imgUrl: url + 'share.png'
}

wx.ready(() => {
  wx.updateAppMessageShareData(shareOptions)
  wx.updateTimelineShareData(shareOptions)
})

document.documentElement.style.fontSize = `${(screen.width > 375 ? screen.width : 375) / 375 * 10}px`
if (process.env.NODE_ENV !== 'production') {
// eslint-disable-next-line no-new
  new VConsole()
}

const app = createApp(App).use(router).use(store, key)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$store = store
app.config.globalProperties.$wechat = wx
app.mount('#app')

let isFocusIn = false
document.body.addEventListener('focusin', () => {
  isFocusIn = true
})

document.body.addEventListener('focusout', () => { // 软键盘关闭事件
  isFocusIn = false
  setTimeout(() => {
    if (!isFocusIn) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }, 100)
})

import "vant/es/popup/style/less";
import _Popup from "vant/es/popup";
import { computed, defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import AreaInfo from '@/components/AreaInfo.vue';
import BindWechatAccount from '@/components/BindWechatAccount.vue';
import { checkPermission } from '@/utils/permission';
export default defineComponent({
  name: 'Home',
  components: {
    BindWechatAccount,
    AreaInfo,
    [_Popup.name]: _Popup
  },

  setup() {
    const store = useStore();
    const hasMultipleAreas = ref(false);

    if (!store.state.userInfo) {
      store.dispatch('getInfo').then(() => {
        hasMultipleAreas.value = Boolean(store.state.areas && store.state.areas.length > 1);
      });
    } else {
      hasMultipleAreas.value = Boolean(store.state.areas && store.state.areas.length > 1);
    }

    const route = useRoute();
    const router = useRouter();
    const showBindDialog = ref(false);
    const authId = ref('');

    if (route.query.authId) {
      authId.value = route.query.authId;
      showBindDialog.value = true;
    }

    const state = ref('/');

    if (route.query.state) {
      state.value = route.query.state;

      if (!authId.value) {
        router.replace(state.value);
      }
    }

    const handleFinishBind = () => {
      store.dispatch('getInfo').then(() => {
        showBindDialog.value = false;
        router.replace(state.value);
      }).catch(error => {
        console.log(error);
      });
    };

    const jumpToQuery = () => {
      router.push('/query');
    };

    const jumpToPatrol = () => {
      router.push('/patrol');
    };

    const jumpToMaintenance = () => {
      router.push('/maintenance');
    };

    const jumpToMaterial = () => {
      router.push('/material');
    };

    const permissions = computed(() => store.state.permissions);
    return {
      hasMultipleAreas,
      showBindDialog,
      authId,
      handleFinishBind,
      jumpToQuery,
      jumpToPatrol,
      jumpToMaintenance,
      jumpToMaterial,
      permissions,
      checkPermission
    };
  }

});
import { defineComponent, ref } from 'vue';
import { bindWechat } from '@/api/session';
import Overlay from '@/components/GeneralOverlay.vue';
import MessageModal from '@/components/GeneralMessageModal.vue';
export default defineComponent({
  name: 'BindWechatAccount',
  components: {
    MessageModal,
    Overlay
  },
  props: {
    authId: {
      type: String,
      required: true
    }
  },
  emits: {
    finishBind() {
      return true;
    }

  },

  setup(props, context) {
    const formRef = ref(null);
    const form = ref({
      name: ''
    });
    const rule = ref({
      name: [{
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      }]
    });
    const bindLoading = ref(false);
    const showToast = ref(false);
    const toastType = ref('success');
    const toastMessage = ref('');

    const handleBindClicked = () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ele = formRef.value;
      ele.validate(valid => {
        if (valid) {
          const data = {
            authId: props.authId,
            username: form.value.name
          };
          bindWechat(data).then(() => {
            toastType.value = 'success';
            toastMessage.value = '绑定成功';
            showToast.value = true;
            setTimeout(() => {
              showToast.value = false;
              context.emit('finishBind');
            }, 2000);
          }).catch(error => {
            toastType.value = 'error';
            toastMessage.value = error.message;
            showToast.value = true;
            setTimeout(() => {
              showToast.value = false;
            }, 2000);
            console.log(error);
          });
        }
      });
    };

    return {
      formRef,
      form,
      rule,
      bindLoading,
      showToast,
      toastType,
      toastMessage,
      handleBindClicked
    };
  }

});
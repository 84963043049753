import { defineComponent, ref } from 'vue';
let delayTimeout;
export default defineComponent({
  name: 'Overlay',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:show'],

  setup(props, context) {
    const showModal = ref(false);

    const maskClicked = () => {
      context.emit('update:show', false);
    };

    return {
      showModal,
      maskClicked
    };
  },

  watch: {
    show(newValue) {
      if (newValue) {
        if (this.delay === 0) {
          this.showModal = true;
        } else {
          delayTimeout = setTimeout(() => {
            this.showModal = true;
          }, this.delay);
        }
      } else {
        if (delayTimeout) {
          clearTimeout(delayTimeout);
        }

        this.showModal = false;
      }
    }

  }
});
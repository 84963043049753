import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/500',
    name: 'Page500',
    component: () => import('../views/500.vue')
  },
  {
    path: '/point/:uuid',
    name: 'Point',
    component: () => import('../views/patrol/Patrol-Point.vue')
  }
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/query',
    name: 'Query',
    component: () => import('../views/Query.vue')
  },
  {
    path: '/patrol',
    name: 'Patrol',
    component: () => import('../views/Patrol.vue')
  },
  {
    path: '/patrol-detail/:id/:name',
    name: 'PatrolDetail',
    component: () => import('../views/patrol/Patrol-Detail.vue'),
    meta: {
      permissionName: 'record'
    }
  },
  {
    path: '/patrol-record/:id',
    name: 'PatrolRecordDetail',
    component: () => import('../views/patrol/Patrol-Record-Detail.vue'),
    meta: {
      permissionName: 'record'
    }
  },
  {
    path: '/point-form/:id',
    name: 'PointForm',
    component: () => import('../views/patrol/Patrol-Form.vue'),
    meta: {
      permissionName: 'report'
    }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance.vue'),
    meta: {
      permissionName: 'ticket'
    }
  },
  {
    path: '/maintenance-detail/:uuid',
    name: 'MaintenanceDetail',
    component: () => import('../views/maintenance/Maintenance-Detail.vue'),
    meta: {
      permissionName: 'ticket'
    }
  },
  {
    path: '/maintenance-form/:uuid',
    name: 'MaintenanceForm',
    component: () => import('../views/maintenance/Maintenance-Form.vue'),
    meta: {
      permissionName: 'ticket'
    }
  },
  {
    path: '/maintenance-add',
    name: 'MaintenanceAdd',
    component: () => import('../views/maintenance/Maintenance-Add.vue'),
    meta: {
      permissionName: 'ticket'
    }
  },
  {
    path: '/material',
    name: 'Material',
    component: () => import('../views/Material.vue')
  },
  {
    path: '/material-inout-detail/:id',
    name: 'MaterialInoutDetail',
    component: () => import('../views/material/MaterialInoutDetail.vue'),
    meta: {
      permissionName: 'inout'
    }
  },
  {
    path: '/material-inout-pickup',
    name: 'MaterialInoutPickup',
    component: () => import('../views/material/MaterialInoutPickup.vue'),
    meta: {
      permissionName: 'inout'
    }
  }
]

export const adminRoutes: Array<RouteRecordRaw> = [
  ...asyncRoutes
]

const create = () => createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

const router = create()

export default router

import "vant/es/picker/style/less";
import _Picker from "vant/es/picker";
import "vant/es/popup/style/less";
import _Popup from "vant/es/popup";
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';
export default defineComponent({
  name: 'AreaInfo',
  components: {
    [_Popup.name]: _Popup,
    [_Picker.name]: _Picker
  },

  setup() {
    const store = useStore();
    const showAreaChangePicker = ref(false);
    const areaName = ref(store.getters.myAreaName);
    const areaNames = ref(store.getters.areaNames);

    const onConfirm = (value, index) => {
      if (store.state.areas) {
        store.dispatch('changeMyArea', store.state.areas[index]).then(() => {
          location.reload();
        }).catch(() => {
          location.reload();
        });
      }
    };

    return {
      showAreaChangePicker,
      areaName,
      areaNames,
      onConfirm
    };
  }

});
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MessageModal',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    message: {
      type: String,
      required: true
    }
  }
});